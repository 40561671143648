import { Button, ThemeProvider } from '@vivino/js-react-common-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  CartItemSourceContext,
  CART_ITEM_SOURCE_TYPES,
} from 'vivino-js/context/CartItemSourceContext';
import { UserProvider } from 'vivino-js/context/UserContext';
import { ErrorBoundary } from 'vivino-js/utilities/ErrorBoundary';
import Page from 'vivino-js/utilities/Page';
import Sentinel from 'vivino-ui/atoms/Sentinel';

import TopListBandWithControls from './components/BandContainer/TopListBandWithControls.container';
import WelcomePage from './components/WelcomePage';

const showAboveFooterMerchandizingWidgets = () => {
  document
    .getElementById('welcome-page-above-footer-merchandizing-widgets')
    ?.classList?.remove('hidden');
};

function render() {
  const preloadedState = window.__PRELOADED_STATE__ || {};

  const welcomePage = document.getElementById('welcome-page');
  const countryTopList = document.getElementById('country-toplists');
  const aboveFooterMerchandizingWidgetsSentinel = document.getElementById(
    'welcome-page-above-footer-merchandizing-widgets-sentinel'
  );
  const campaignCardActionButtons = document.getElementsByClassName('article-campaign-card-button');

  countryTopList &&
    ReactDOM.render(
      <Page>
        <CartItemSourceContext.Provider
          value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_COMPUTATIONAL_TOPLIST}
        >
          <TopListBandWithControls
            topLists={preloadedState.countryTopLists}
            currencyCode={preloadedState.currencyCode}
            trackingSource={'frontpage'}
            trackingName={'country-toplists'}
          />
        </CartItemSourceContext.Provider>
      </Page>,
      countryTopList
    );

  welcomePage &&
    ReactDOM.render(
      <Page>
        <UserProvider user={window.__PRELOADED_SIGNED_IN_USER__}>
          <WelcomePage
            countryCode={window.__PRELOADED_COUNTRY_CODE__}
            currencyCode={preloadedState.priceRange?.currency?.code}
            priceRange={preloadedState.priceRange}
            recentPriceRange={preloadedState.recentPriceRange}
            recentWineStyleIds={preloadedState.recentWineStyleIds}
            userCountryCode={preloadedState.userCountryCode}
            countriesWithoutReferralProgram={preloadedState.countriesWithoutReferralProgram}
            countryTopLists={preloadedState.countryTopLists || []}
            promotions={preloadedState.promotions || []}
          />
        </UserProvider>
      </Page>,
      welcomePage
    );

  if (campaignCardActionButtons.length > 0) {
    for (const button of campaignCardActionButtons) {
      ReactDOM.render(
        <ErrorBoundary>
          <ThemeProvider>
            <Button className="widget-article-campaign-strip_shopNowButton">
              {button.dataset.buttonText}
            </Button>
          </ThemeProvider>
        </ErrorBoundary>,
        button
      );
    }
  }

  aboveFooterMerchandizingWidgetsSentinel &&
    ReactDOM.render(
      <Sentinel onIntersect={showAboveFooterMerchandizingWidgets} />,
      aboveFooterMerchandizingWidgetsSentinel
    );
}

render();

export { render };
