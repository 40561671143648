/* eslint-disable react/prop-types */

import React, { Component } from 'react';

export function withToplistControls(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.getToplistControlClass = (index, modifier) => {
        const className = 'market-band__toplist-controls__button--' + index + ' ' + modifier;
        if (index === this.props.activeListIndex) {
          return 'market-band__toplist-controls__button selected' + ' ' + className;
        }
        return 'market-band__toplist-controls__button' + ' ' + className;
      };
      this.getCurrencyClassWithSymbol = () => {
        switch (this.props.currencyCode) {
          case 'EUR':
            return {
              classModifier: 'euro',
              symbol: '€',
            };
          case 'USD':
            return {
              classModifier: 'dollar',
              symbol: '$',
            };
          case 'GBP':
            return {
              classModifier: 'pound',
              symbol: '£',
            };
          default:
            return {
              classModifier: 'default',
              symbol: '',
            };
        }
      };
      this.renderToplistControl = (id, index) => {
        const currencyObj = this.getCurrencyClassWithSymbol();
        let linkContent = null;
        if (currencyObj.symbol) {
          linkContent = currencyObj.symbol.repeat(index + 1);
        }
        return (
          <div
            key={index}
            className={this.getToplistControlClass(index, currencyObj.classModifier)}
            role="button"
          >
            <a
              className="control"
              onClick={this.props.loadToplist}
              data-js-track-link-category="Band"
              data-js-track-link-name={`Ctrl btn - ${this.props.trackingName} on ${this.props.trackingSource}`}
              data-toplist-id={id}
              data-toplist-index={index}
              data-item-name={index + 1}
              href="#"
              data-testid={`ctrl-btn-${index}`}
            >
              {linkContent}
            </a>
          </div>
        );
      };
    }
    render() {
      return (
        <div>
          <div
            className="market-band__toplist-controls"
            data-track-type="nv"
            data-item-type="price-range-icon"
          >
            {this.props.toplistsIds.map(this.renderToplistControl)}
            <div className="market-band__toplist-controls__text">
              {this.props.activeToplistText}
            </div>
          </div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
}
